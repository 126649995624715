import userService from '../../services/userService';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const Dashboard = () => {
	const { role } = userService.getUser();
	const { formatMessage } = useIntl();

	if (role === 'client') window.location.href = '/members';

	return (
		<>
			<BackImgEndSeason>
				<p>
					{formatMessage(
						{ id: 'endSeason.p3' },
						{
							strong: (chunks) => <strong>{chunks}</strong>,
						}
					)}
				</p>
				<p>
					{formatMessage(
						{ id: 'endSeason.p4' },
						{
							strong: (chunks) => <strong>{chunks}</strong>,
						}
					)}
				</p>
			</BackImgEndSeason>
		</>
	);
};

const BackImgEndSeason = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50vh;
	background: url('/images/endSeason2.webp') no-repeat center center;
	& > p {
		color: #fff;
		font-size: 1.5rem;
		text-align: center;
		margin-top: 2rem;
		line-height: 3rem;
		width: 75%;
	}
`;

export default Dashboard;
