import { useContext } from 'react';
import { InsuranceContext } from '../../context/insurance';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import BigImageLayout from 'components/common/BigImageLayout';
import styled from 'styled-components';
import Button from 'components/common/Button';
import { useIntl } from 'react-intl';
const InsuranceWrap = () => {
	const { state } = useContext(InsuranceContext);
	const { formatMessage } = useIntl();
	return (
		<>
			<BigImageLayout url="/images/step1.jpg">
				<EndSeason>
					<p>{formatMessage({ id: 'endSeason.p1' })}</p>
					<p><strong>{formatMessage({ id: 'endSeason.p2' })}</strong></p>
					<Button to="/">{formatMessage({ id: 'endSeason.button' })}</Button>
				</EndSeason>
			</BigImageLayout>

			{/* {state.step === 1 && (
				<BigImageLayout url="/images/step1.jpg">
					<Step1 />
				</BigImageLayout>
			)}
			{state.step === 2 && (
				<BigImageLayout url="/images/step2.jpg">
					<Step2 />
				</BigImageLayout>
			)}
			{state.step === 3 && (
				<BigImageLayout url="/images/step3.jpg">
					<Step3 />
				</BigImageLayout>
			)}
			{state.step === 4 && (
				<BigImageLayout url="/images/step4.jpg">
					<Step4 />
				</BigImageLayout>
			)}
			{state.step === 5 && (
				<BigImageLayout url="/images/login.jpg">
					<Step5 />
				</BigImageLayout>
			)} */}
		</>
	);
};

const EndSeason = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	margin-top: 200px;
	color: ${({ theme }) => theme.primaryColor};

	& > p {
		font-size: 1.5rem;
		text-align: center;
		margin-top: 2rem;
		line-height: 3rem;
	}

	& > p:nth-child(2) {
		font-weight: bold;
	}
`;
export default InsuranceWrap;
